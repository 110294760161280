import http from "../http-common";

class DataService {
  async GetContextMenu(params) {
    return await http.instanceCommon.get("/Common/GetContextMenu?" + params);
  }
  async CheckPrivilege(params){
    return await http.instanceCommon.get("/Common/HasPermission?" + params);
  }
  async GetModuleMasterIdByModuleName(params) {
      return await http.instanceCommon.get("/Common/GetModuleMasterIdByModuleName?" + params);
  }
  async GetHideContextMenuConfig() {
      return await http.instanceCommon.get("/Common/GetHideContextMenuConfig");
  }
  async GetConfigurationValueForCompanyByKey(param) {
      return await http.instanceCommon.get("/Common/GetConfigurationValueForCompanyByKey?" + param);
  }
  async SaveHideContextMenuConfig(param) {
      return await http.instanceCommon.post("/Common/SaveHideContextMenuConfig?" + param);
  }
  async SaveNote(params) {
    return await http.instance.post("/Project/SaveNote", { postString: params })
  }
  async GetDDLData(params) {
      return await http.instanceCommon.get("/Common/GetDDLData?" + params);
  }
  async CommonUpdateStatus(params) {
      return await http.instanceCommon.post("/Common/CommonUpdateStatus", { postString: params })
  }
  async ProjectListing(param) {    
      return await http.instance.get("/Project/ProjectListing?" + param);
  }
  async GetStatusList(params) {
      return await http.instanceCommon.get("/Common/GetStatusList?" + params);
  }
  async onStatusList(params) {
      return await http.instance.get("/Common/GetStatusDDLData?" + params);
  }
  async DeleteProject(params) {
      return await http.instance.post("/Project/DeleteProject", { postString: params });
  } 
  async CommonAssignTag(params) {
        return await http.instance.post("/Common/CommonAssignTag", { postString: params });
  } 
  async ImportProjectPost(params){    
    return await http.instance.post("/Project/ImportProjectPost", params);
  }
  async BulkUploadProject(params){    
    return await http.instance.get("/Project/BulkUploadProject?"+params);
  }
  async SaveBulkUploadProject(params){ 
    return await http.instance.post("/Project/SaveBulkUploadProject",{ postString: params });
  }
  async MarkAsUnPin(params){     
    return await http.instance.post("/Project/SaveUnPin",{ postString: params });
  }
  async MarkAsFavorite(params){ 
    return await http.instance.post("/Project/SavePin",{ postString: params });
  }  
  async ClearView(params) {
    return await http.instanceCommon.post("/FormLayout/ClearView", { postString: params });
  }
  async GetViewList(params) {    
    return await http.instanceCommon.get("/FormLayout/GetViewList?" + params);
  }
  async GetViewById(params) {
    return await http.instanceCommon.get("/FormLayout/GetViewById?" + params);
  }
  async ApplyView(params) {
    return await http.instanceCommon.post("/FormLayout/ApplyView", { postString: params });
  }
  async SaveView(params) {
    return await http.instanceCommon.post("/FormLayout/SaveView", { postString: params });
  }
  async ViewListFields(params) {
    return await http.instanceCommon.get("/FormLayout/ViewListFields?" + params);
  }
  async ViewListUsers(params) {
    return await http.instanceCommon.get("/FormLayout/ViewListUsers?" + params);
  }
  async SearchCriteriaListing(params) {
    return await http.instanceCommon.get("/Common/SearchCriteriaListing/", { params });
  }
  async GetFieldName(params) {
    return await http.instanceCommon.get("/Common/GetFieldName?"+ params);
}
async GetOperator(params) {  
  return await http.instanceCommon.get("/Common/GetOperator?"+ params);
}
async SearchCriteriaById(params) {
  return await http.instance.get("/Form/SearchCriteriaById?"+params);
}
async SearchCriteriaByIdView(params) {
  return http.instanceCommon.get("/Common/ViewFlterById?"+params);
}
async CommonCommonDelete(params) {
  return await http.instanceCommon.post("/Common/CommonDelete", { postString: params });
}
async GetDynamicForm(params) {  
  return await http.instanceCommon.get("/FormLayout/GetForm?" + params);
}
async SaveDynamicForm(params) {
  return http.instanceCommon.post("/FormLayout/SaveDynamicForm", { postString: params })
}
async TemplateListing(params){    
  return await http.instance.get("/Project/TemplateListing?"+params);
}
async TemplateByTemplateId(params){    
  return await http.instance.get("/Project/TemplateByTemplateId?"+params);
}
async SaveChannel(params){ 
  return await http.instance.get("/Project/SaveChannel?"+params);
}
async SavePriority(params){ 
  return await http.instance.get("/Project/SavePriority?"+params);
}
async SaveTag(params) {
  return http.instanceCommon.post("/Tag/SaveTag", { postString: params })
}
async AddMemberInProject(params){    
  return await http.instance.get("/Project/GetMemberListing?"+params);
}
async GetUserTitle(){    
  return await http.instance.get("/Project/GetUserTitle");
}
async GetUserTypes(params){    
  return await http.instance.get("/Project/GetUserTypes?"+params);
}
async GetUserDepatment(){    
  return await http.instance.get("/Project/GetAllDepartment");
}
async GetAllRolesOfUserType(params){    
  return await http.instance.get("/Project/GetAllRolesOfUserType?"+params);
}
async GetAllPolicy(params){    
  return await http.instance.get("/Project/GetAllPolicy?"+params);
}
async SavedUser(params) {
  return http.instance.post("/Project/SavedUser", { postString: params })
}
async TemplateMasterListing(){    
  return await http.instance.get("/Project/TemplateMasterListing");
}
async GetTeamListing(params){    
  return await http.instance.get("/Project/TeamListing?" + params);
}
async SaveDefault(params) {
  return http.instance.post("/Project/MakeProjectDefaultForUsers", { postString: params })
}
async GetProjectQuickViewDetails(params){    
  return await http.instance.get("/Project/GetQuickViewByProjectId?"+params);
}
async GetFileDetails(params){    
  return await http.instance.get("/Project/FileListing?"+params);
}
async UserList(params){    
  return await http.instance.get("/Project/UserList?"+params);
}
async SaveCloneProject(params){      
  return await http.instance.get("/Project/SaveCloneProject?"+params);
}
async ProjectConfiguration(params){      
  return await http.instance.get("/Project/ProjectConfiguration?"+params);
}
async GetModuleKeys(params){      
  return await http.instance.get("/Project/GetModuleKeys?"+params);
}
async SaveConfigurations(params) {  
  return await http.instanceCommon.post("/Common/ManageDocUserStorageSettingData",params);
}
async GetProjectDashboardWidgets(params){      
  return await http.instance.get("/Project/GetProjectDashboardWidgets?"+params);
}
async GetProjectDetails(params){      
  return await http.instance.get("/Project/ProjectDetails?"+params);
}
 async GetActivityDatalisting (params){
return await http.instance.get("/Project/ActivityListing?" + params);
}
async GetTabListingdata(params){
  return await http.instance.get("/Project/TabListing?"+params);
}
async GetProjectTaskListing(params){
  
  return await http.instance.get("/Project/ProjectTaskListing?"+params);
}
async UploadImageFile(params) {  
  return await http.instance.post("/Project/UploadImageFile",params);
}
async SaveTeamMember(params) {   
  return await http.instance.post("/Project/AddUpdateFile",params);
}
async GetClientsDataDetails(params){    
  return await http.instance.get("/Project/ClientListing?"+params);
}
async GetAssociateClientsDataDetails(params){      
  return await http.instance.get("/Project/GetAssociativeList?"+params);
}
CallDetailListing(params) {
  return http.instance.get("/Project/CallDetailListing?"+ params);
}
ProjectNoteListing(params) {
  return http.instance.get("/Project/NoteListing?"+ params);
}
async SaveAddTeam(params) {
  return http.instance.post("/Project/SaveAddTeam", { postString: params })
}
async TemplateList(){
  return await http.instance.get("/Project/TemplateList/");
}
async Create(params){
  return await http.instance.get("/Project/Create?"+params);
}
async GetQuotationOwners(params){
  return await http.instance.get("/Project/GetQuotationOwners?"+params);
}
async GetQuotationNumber(){
  return await http.instance.get("/Project/GetQuotationNumber/");
}
async Previewtemplate(params){
  return await http.instance.get("/Project/Previewtemplate?"+params);
}
async SaveQuote(params){  
  return http.instance.post("/Project/SaveQuote", { postString: params })
}
async TaskListing(params){
  return await http.instance.get("/Project/TaskListing?"+params);
}
async DeleteTask(params) {
  return await http.instance.post("/Project/DeleteTask", { postString: params });
} 
async StatusListing(params) {
  return await http.instance.get("/Project/StatusListing?"+params);
}
async GetTaskComment(params){
  return await http.instance.get("/Project/TaskLogCommentDetail?"+params);
}
async GetBlobUrl(){
  return await http.instanceCommon.get("/Common/GetBlobUrl/");
}
async SaveTaskLog(params) {
  return await http.instance.post("/Project/Save_TaskLog", { postString: params });
}
async GetClientListing (params){
  return await http.instance.get("/Project/ClientListing?"+params);
} 
async ProjectRequestList(params) {
  return await http.instance.get("/Project/ProjectRequestList?"+params);
}
async UpdateProjectRequestStatus(params) {
  return await http.instance.post("/Project/UpdateProjectRequestStatus", { postString: params });
}
async GetUsersList(){
  return await http.instance.get("/Project/GetUsersList/");
}
async AddProjectRequestOwner(params) {
  return await http.instance.get("/Project/AddProjectRequestOwner?"+  params );
}
async AcceptProjectRequest(params) {
  return await http.instance.post("/Project/AcceptProjectRequest", { postString: params });
}
async ProjectRequestAccept(params) {
  return await http.instance.post("/Project/ProjectRequestAccept", { postString: params });
}
async RejectProjectRequest(params) {
  return await http.instance.post("/Project/RejectProjectRequest", { postString: params });
}
async SaveRejectProjectRequest(params) {
  return await http.instance.post("/Project/ProjectRequestAccept", { postString: params });
}
async ConvertToProject(params) {
  return await http.instance.post("/Project/ConvertToProject", { postString: params });
}
async AddProjectRequestNotes(params) {
  return await http.instance.get("/Project/GetProjectRequestNotes?"+  params );
}
async MilestoneListing(params) {
  return await http.instance.get("/Project/MilestoneListing?"+  params );
}
async StageListing(params) {
  return await http.instance.get("/Project/StageListing?"+  params );
}
async ExpenseLimitListing(params) {
  return await http.instance.get("/Project/ExpenseLimitListing?"+  params );
}
async SaveProjectRequestNotes(params) {
  return await http.instance.post("/Project/SaveProjectRequestNotes", { postString: params });
}
async GetProjectPhaseList(params) {
  return await http.instance.get("/Project/PhaseListing?"+  params );
}
async DisplayQuotation (params){
  return await http.instance.get("/Project/DisplayQuotation?"+  params );
}
async GetDetails (params){
  return await http.instance.get("/Project/Details?"+  params );
}
async ViewFiles (params){
  return await http.instance.get("/Project/ViewFiles?"+  params );
}
async SaveClients(params){   
  return await http.instance.post("/Project/SaveClients",{ postString : params });
}
async DeleteClients(params){ 
  return await http.instance.post("/Project/DeleteClients?"+params);
}
async GetFilesDetails(params){    
  return await http.instance.get("/Project/FileListing?"+params);
}
async AddSavePhase(params) {
  return await http.instance.post("/Project/AddSavePhase", { postString: params });
}
async DownloadSingleFile(params){  
  return await http.instancebaseDocStorage.get("/DownloadFileSingle?"+ params,{responseType:"blob"});
}
async UploadNewVersion(params) {   
  return await http.instance.post("/Project/UploadNewVersion",params);
}
async GetFilesOldVersionDetails(params){      
  return await http.instance.get("/Project/FileVersionListing?"+params);
}
async DeleteOldFiles(params){ 
  return await http.instance.post("/Project/DeleteOldFiles?"+params);
}
async GetAvailableAndAssignedListing(params){ 
  return await http.instance.get("/Project/GetAvailableAndAssignedUserListing?"+params);
}
async SaveShareData(params){  
  return await http.instance.get("/Project/SaveShareData?"+params);
}
  async DeleteTask(params) {
    return await http.instance.post("/Project/DeleteTask", { postString: params });
  }
  async StatusListing(params) {
    return await http.instance.get("/Project/StatusListing?" + params);
  }
  async GetTaskComment(params) {
    return await http.instance.get("/Project/TaskLogCommentDetail?" + params);
  }
  async GetBlobUrl() {
    return await http.instanceCommon.get("/Common/GetBlobUrl/");
  }
  async SaveTaskLog(params) {
    return await http.instance.post("/Project/Save_TaskLog", { postString: params });
  }
  async GetClientListing(params) {
    return await http.instance.get("/Project/ClientListing?" + params);
  }
  async ProjectRequestList(params) {
    return await http.instance.get("/Project/ProjectRequestList?" + params);
  }
  async UpdateProjectRequestStatus(params) {
    return await http.instance.post("/Project/UpdateProjectRequestStatus", { postString: params });
  }
  async GetUsersList() {
    return await http.instance.get("/Project/GetUsersList/");
  }
  async AddProjectRequestOwner(params) {
    return await http.instance.get("/Project/AddProjectRequestOwner?" + params);
  }
  async AcceptProjectRequest(params) {
    return await http.instance.post("/Project/AcceptProjectRequest", { postString: params });
  }
  async ProjectRequestAccept(params) {
    return await http.instance.post("/Project/ProjectRequestAccept", { postString: params });
  }
  async RejectProjectRequest(params) {
    return await http.instance.post("/Project/RejectProjectRequest", { postString: params });
  }
  async SaveRejectProjectRequest(params) {
    return await http.instance.post("/Project/ProjectRequestAccept", { postString: params });
  }
  async ConvertToProject(params) {
    return await http.instance.post("/Project/ConvertToProject", { postString: params });
  }
  async AddProjectRequestNotes(params) {
    return await http.instance.get("/Project/GetProjectRequestNotes?" + params);
  }
  async StageListing(params) {
    return await http.instance.get("/Project/StageListing?" + params);
  }
  async ExpenseLimitListing(params) {
    return await http.instance.get("/Project/ExpenseLimitListing?" + params);
  }
  async SaveProjectRequestNotes(params) {
    return await http.instance.post("/Project/SaveProjectRequestNotes", { postString: params });
  }
  async GetProjectPhaseList(params) {
    return await http.instance.get("/Project/PhaseListing?" + params);
  }
  async DisplayQuotation(params) {
    return await http.instance.get("/Project/DisplayQuotation?" + params);
  }
  async GetDetails(params) {
    return await http.instance.get("/Project/Details?" + params);
  }
  async ViewFiles(params) {
    return await http.instance.get("/Project/ViewFiles?" + params);
  }  
  async DeleteClients(params) {
    return await http.instance.get("/Project/DeleteClients?" + params);
  } 
  async SaveMilestone(params) {
    return http.instance.post("/Project/SaveMilestone", { postString: params })
  }
  async AddSavePhase(params) {
    return await http.instance.post("/Project/AddSavePhase", { postString: params });
  }   
  async GetMilestoneCriteriaListForDDL(params) {
    return await http.instance.get("/Project/GetMilestoneCriteriaListForDDL?" + params);
  }
  async GetToDoListing(params) {
    return await http.instance.get("/Project/ToDoList?" + params);
  }
  StatusDropdown(params) {
    return http.instance.get("/Project/StatusListing?" + params);
  }
  async TimeTrackWeeklyDataList(params) {
    return await http.instance.get("/Project/TimerWeeklyHoursListing?" + params);
  }
  async AddEditPhaseListing(params) {
    return await http.instance.get("/Project/AddEditPhaseListing?" + params);
  }
  async ProjectOverview(params) {
    return await http.instance.get("/Project/ProjectOverview?" + params);
  }
  async SavePhase(params) {
    return await http.instance.post("/Project/SavePhase", { postString: params });
  }
  async DeletePhase(params) {
    return await http.instance.post("/Project/DeletePhase", { postString: params });
  }
  async GetStageType(params) {
    return await http.instance.get("/Project/GetStageType/");
  }
  async AddSaveStage(params) {
    return await http.instance.post("/Project/AddSaveStage", { postString: params });
  }
  async SaveStage(params) {
    return await http.instance.post("/Project/SaveStage", { postString: params });
  }
  async FetchAddEditStage(params) {
    return await http.instance.get("/Project/AddEditStageListing?" + params);
  }
  async GetTaskDDLData(params) {
    return await http.instance.get("/Common/GetTaskDDLData?" + params);
  }
  async GetGanttChartData(params) {
    return await http.instance.get("/Project/GanttChart?" + params);
  }
  async SaveSprint(params) {
    return await http.instance.post("/Project/SaveSprint", { postString: params });
  }  
  async SaveBackLog(params) {
    return await http.instance.post("/Project/SaveBackLog", { postString: params });
  }  
  async FetchBacklogListing(params) {
    return await http.instance.get("/Project/GetBackLogList?" + params);
  }
  async PriorityDropdown() {
    return await http.instance.get("/Project/PriorityDropdownList/");
  }
  async GetBlobUrl(params){
    return await http.instanceCommon.get("/Common/GetBlobUrl/" );
  }
  async UpdateAssignUser(params) {
    return await http.instance.post("/Project/UpdateAssignUser", { postString: params });
  }
  async FetchBackLogDetails(params) {
    return await http.instance.get("/Project/FetchBackLogDetails?" + params);
  }
  async FetchGetSprintList(params) {
    return await http.instance.get("/Project/GetSprintList?" + params);
  }
  async SaveEpic(params) {
    return await http.instance.post("/Project/SaveEpic", { postString: params });
  }
  async GetMilestoneNumber(params) {
    return await http.instance.get("/Project/GetMilestoneNumber?" + params);
  }
  async GetMilestoneDetails(params) {
    return await http.instance.get("/Project/GetMilestoneDetails?" + params);
  }
  async LoadTaskByProjectId(params) {
    return await http.instance.get("/Project/LoadTaskByProjectId?" + params);
  }
  async NoteListing(params) {
    return await http.instance.get("/Project/NoteListing?" + params);
  }
  async AssociateDeassociate(params) {
    return await http.instance.get("/Project/AssociateDeassociate?" + params);
  }
  async GetTemplateListData(params){
    return await http.instance.get("/Project/GetProjectTemplate?"+params);
  }
  async GetTemplateList() {
    return await http.instance.get("/Project/GetTemplateList/");
  }
  async DeleteTeamMembers(params){
    return await http.instance.get("/Project/DeleteTeamMembers?"+params);
  }
  async GetAllCategoriesByCompanyId() {
    return await http.instance.get("/Expense/GetCategoryName/");    
  }
  async GetUserList(params) {
    return await http.instance.get("/Expense/GetUserList?" + params);
  }
  async SaveCategory(params) {
    return await http.instance.post("/Expense/SaveCategory", { postString: params })
  }
  async SaveExpenseLimit(params) {
    return await http.instance.post("/Expense/SaveExpenseLimit ", { postString: params })
  }
  async ExpenseListing(params) {    
    return await http.instance.get("/Project/ExpenseLimitListing?"+  params );
  }
  async GetExpenseLimit(params) {    
     return await http.instance.get("/Expense/GetExpenseLimit?"+  params );
  }
  async GetDesignationDetails(){    
    return await http.instance.get("/Project/GetDesignationData");
  }
  async GetMemebrDetails(params){    
    return await http.instance.get("/Project/GetMemberData?" + params);
  }
  async GlobalTaskListing(params){
    return await http.instance.get("/Project/GlobalTaskListing?"+params);
  }
  async GetProjectVirtualUserbyId(params){
    return await http.instance.get("/Project/GetProjectVirtualUserbyId?"+params);
  }
  async ProjectVirtualUser(params) {
    return await http.instance.post("/Project/ProjectVirtualUser ", { postString: params })
  }
  async VirtualUserConfigurationListing(params) {    
    return await http.instance.get("/Project/VirtualUserConfigurationListing?"+  params );
 }

  async UpdateMemebrDetails(params){
    return await http.instance.post("/Project/UpdateTeamMember?"+params);
  }
  async SaveGlobalTask(params){
    return await http.instance.post("/Project/SaveGlobalTask", { postString: params })
  }
  async ProjectBaseRate() {
    return await http.instance.get("/Project/ProjectBaseRate/");
  }
  async ProjectWorkingDays(params){
    return await http.instance.get("/Project/ProjectWorkingDays?"+params);
  }
  async MessageListing(params){
    return await http.instance.get("/Project/MessageListingNew?"+params);
  } 
  async GetChildSprint(params){
    return await http.instance.get("/Project/PrjectTaskListing?"+params);
  }  
  async UserList(params) {    
    return await http.instance.get("/Project/UserList?"+  params );
  }
  async Post(params){ 
    return await http.instance.post("/Project/Post?"+ params );
  }
  async SaveMessagePost(params) {   
    return await http.instance.post("/Project/SaveMessagePost",params);
  }
 
  async AddStatus(params){
    return await http.instance.post("/Project/AddStatus?"+params);
  }

  async GetChecklistDeatils(params){
    return await http.instance.get("/Project/GetChecklistData?"+params);
  }

  async AssociateChecklist(params){
    return await http.instance.post("/Project/AssociateChecklist?"+params);
  }

  async GetSubTaskDetails(params){
    return await http.instance.get("/Project/PrjectTaskListing?"+params);
  }

  async AddMemberToTask(params){
    return await http.instance.post("/Project/AddMemberToTask?"+params);
  }

  async UpdateTask(params){
    
    return await http.instance.post("/Project/UpdateAllTasks" , { postString: params });
  } 

  async AddCommentToTask(params){
    return await http.instance.post("/Project/AddCommentToTask?"+params);
  }

  async DeleteSubTask(params){
    return await http.instance.post("/Project/DeleteSubTask?"+params);
  }

  async GetComments(params){
    return await http.instance.get("/Project/GetTaskComment?"+params);
  }
  async DeleteMessage(params) {
    return http.instance.post("/Project/DeleteMessage", { postString: params })
  }
  async GetAssociateChecklist(params){
    return await http.instance.get("/Project/GetAssociateChecklistToTask?"+params);
  }
  async PreviewData(params){
    
    return await http.instance.get("/Project/GetChecklistItems?"+params);
  }

  async SaveChecklistItemData(params) {
    
    return await http.instance.post("/Project/SaveChecklistItemData" , { postString: params });
  }

  async DeleteChecklistAndItems(params){
    return await http.instance.post("/Project/DeleteChecklistItemData?"+params);
  }

  async GetChecklistItems(params){
    return await http.instance.get("/Project/GetChecklistItemData?"+params);
  }

  async GetTeamAndUserData(params){
    return await http.instance.get("/Project/GetTeamAndUserData?"+params);
  }
  async GetTeamForAssociate(params){
    return await http.instance.get("/Project/GetTeamForAssociate?"+params);
  }
  async GetTeamForAssociateProject(params){
    return await http.instance.get("/Project/GetTeamForAssociateProject?"+params);
  }
  async GetAssociateUserList(params){
    return await http.instance.get("/Project/GetTeamForAssociateUser?"+params);
  }
  async DeleteTeam(params){ 
    return await http.instance.get("/CheckList/GetDeleteTeam?"+params);
  }
  async getBudgetListing(params){ 
    return await http.instance.get("/Budget/BudgetList?"+params);
  }
  async getExpenseListingForPieChart(params){ 
    return await http.instance.get("/Expense/ExpenseListing?"+params);
  }
  async GetExpenseCategory(params){ 
    return await http.instance.get("/Budget/GetDDLData?"+params);
  }
  async ManageBudget(params){ 
    return await http.instance.post("/Budget/ManageBudget",{ postString: params });
  }  
  async ExpenseListing(params){
    return await http.instance.get("/Expense/ExpenseListing?"+params);
  }
  async GetExpenseFileList(params) { 
    return  await http.instance.get("/Expense/GetExpenseFileList?"+ params );
  }
  async GetConfigurationValue(params){   
    return await http.instance.get("/Expense/GetConfigurationValue?"+params);
  }
  async GetTaskListingddl(params) { 
    return await http.instance.get("/Expense/GetTaskByProject?" + params);
  }
  async CheckAmountDateRange(params) {  
    return await http.instance.post("/Expense/CheckAmountDateRange/", { postString: params })
  }
  async ViewPageDetails(params) {  
    return await http.instance.get("/Common/ViewPageDetails?" + params);
  }
  async AddCategory(params){ 
    return await http.instance.post("/Budget/AddCategory",{ postString: params });
  } 
  async GetChildStageTaskChild(params){
    return await http.instance.get("/Project/GetChildStageTaskChild?" + params);
  }
  async GetProjectTaskListingByProjectId(params){
    return await http.instance.get("/Project/GetProjectTaskListingByProjectId?" + params);
  }
  async GetMilestoneListingForDDL(params){
    return await http.instance.get("/Project/GetMilestoneListingForDDL?" + params);
  }
  async GetTimeLineData(params) {
    return await http.instance.get("/Project/GetTimeGanttChartDaTa?" + params);
  }
  async FetchConstructionTask(params){
    return await http.instance.get("/Project/FetchConstructionTask?" + params);
  }
  async DeleteStage(params) {
    
    return await http.instance.post("/Project/DeleteStage", { postString: params });
  }
  async GetMessageDetails(params) {
    return await http.instance.get("/Project/GetMessageDetails?" + params);
  }
  
}
export default new DataService();
